/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Vienne"), React.createElement("p", null, "Pour se loger, Vienne s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/at/vienna/innerestadt.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Innere Stadt"), " (1er arrondissement) : très recherché, le centre historique accueille plus de 60 musées."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/at/vienna/leopoldstadt.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Leopoldstadt"), " (2e), au nord-est : un quartier agréable, connu pour le parc du Prater qui borde le Danube."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/at/vienna/landstrasse.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Landstrasse"), " (3e), à l’est : près du palais du Belvédère, quartier résidentiel avec de nombreux espaces verts."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/at/vienna/wieden.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Wieden"), " (4e), au sud : non loin du marché de Naschmarkt et en contact direct avec le centre."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/at/vienna/neubau.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Neubau"), " (7e), à l’ouest : quartier jeune autour du MuseumsQuartier et des boutiques de Mariahilferstrasse."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
